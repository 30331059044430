
import Vue, { PropType } from "vue";

import http from "@/http";

import { date, month } from "@/filters";

import { Report, Agreement, Breadcrumb } from "@/interfaces";

import { mdiPlus, mdiDelete } from "@mdi/js";

interface Response {
  url: string;
}
export default Vue.extend({
  filters: {
    date,
    month
  },
  props: {
    agreement: {
      type: Object as PropType<Agreement>,
      required: true
    }
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "建物",
          disabled: false,
          to: "/buildings"
        },
        {
          text: `${this.agreement.building.name}`,
          disabled: false,
          to: `/building/${this.agreement.building.id}`
        },
        {
          text: "収支報告",
          disabled: false
        }
      ];
    },
    icon() {
      return {
        mdiPlus,
        mdiDelete
      };
    },
    id(): number {
      const { id } = this.$route.params;

      return +id;
    },
    headers() {
      return [
        {
          text: "年月",
          value: "remittance_month"
        },
        {
          text: "登録日",
          value: "created_at"
        },
        { text: "", value: "action", sortable: false }
      ];
    }
  },
  methods: {
    async click(item: Report) {
      const url = `/reports/${item.id}`;

      const { data } = await http.get<Response>(url);

      window.location.href = data.url;
    },
    async deleteItem(item: Report) {
      const url = `/reports/${item.id}`;

      await http.delete(url);

      if (this.agreement) {
        this.agreement.reports = this.agreement.reports.filter(report => {
          return report.id !== item.id;
        });
      }
    }
  }
});
