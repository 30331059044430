import dayjs from "dayjs";
import "dayjs/locale/ja";
import relativeTime from "dayjs/plugin/relativeTime";

export function date(value: string) {
  if (!value) {
    return "";
  }
  return dayjs(value).format("YYYY-MM-DD");
}

export function dateTime(value: string) {
  if (!value) {
    return "";
  }
  return dayjs(value).format("YYYY-MM-DD HH:mm");
}

export function month(value: string) {
  if (!value) {
    return "";
  }
  return dayjs(value).format("YYYY-MM");
}

export function fromNow(value: string) {
  if (!value) {
    return "";
  }
  dayjs.locale("ja");
  dayjs.extend(relativeTime);

  return dayjs(value).fromNow();
}

export function excerpt(value: string, count = 30) {
  if (value.length > count) {
    return `${value.substring(0, count)}...`;
  }

  return value;
}
